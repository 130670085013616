dmx.Flow = dmx.createClass({

    constructor: function(parent) {
        if (!(this instanceof dmx.Flow)) {
            return new dmx.Flow(parent);
        }

        if (!window.Promise) {
            console.warn('Promises are not supported, flows can not be used');
        }

        this._execStep = this._execStep.bind(this);

        this.scope = new dmx.DataScope({}, parent);
        this.output = {};
    },

    run: function(flow) {
        var self = this;

        this.output = {};

        return this._exec(flow.exec || flow).then(function() {
            if (dmx.debug) {
                console.debug('finished', self.output);
            }
            return self.output;
        });
    },

    _each: function(arr, fn) {
        return Promise.resolve(arr).then(function(arr) {
            arr = Array.isArray(arr) ? arr : [arr];

            return arr.reduce(function(prev, curr, i) {
                return prev.then(function() {
                    return fn(curr, i, arr.length);
                });
            }, Promise.resolve()).then(function() {
                return arr;
            });
        });
    },

    _exec: function(flow) {
        var self = this;

        if (flow.steps) {
            var promise = this._each(flow.steps, this._execStep);
            
            if (flow.catch) {
                promise.catch(function(err) {
                    return self._each(flow.catch, self._execStep);
                });
            }
            
            return promise;
        }

        return this._each(flow, this._execStep);
    },

    _execStep: function(step) {
        var self = this;

        if (dmx.debug) {
            console.debug('exec step', step);
        }

        for (var name in step) {
            if (dmx.__actions[name]) {
                var action = dmx.__actions[name].bind(this);
                var options = step[name];

                if (dmx.debug) {
                    console.debug('exec action', name, options);
                }
                
                return Promise.resolve(action(options)).then(function(output) {
                    if (options.name) {
                        //self.data[options.name] = output
                        if (dmx.debug) {
                            console.debug('set data', options.name, output);
                        }
                        
                        self.scope.set(options.name, output);

                        if (options.output) {
                            if (dmx.debug) {
                                console.debug('set output', options.name, output);
                            }
                            self.output[options.name] = output;
                        }
                    }
                });
            } else {
                throw new Error('Action ' + name + ' was not found.');
            }
        }
    },

    parse: function(value) {
        if (value == null) return value;

        value = value.valueOf();

        if (typeof value == 'object') {
            var obj = value.slice ? [] : {};

            for (var key in value) {
                obj[key] = this.parse(value[key], this.scope);
            }

            return obj;
        }

        if (typeof value == 'string' && value.indexOf('{{') != -1) {
            return dmx.parse(value, this.scope);
        }

        return value;
    }

});

dmx.Flow.run = function(flow, data) {
    var instance = new dmx.Flow(data);
    return instance.run(flow);
};
